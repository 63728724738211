import React from "react"
import logoBlack from "../images/logo-black.svg"
import AnnouncementBar from "../components/announcement-bar"

import "../scss/navbar.scss"
import "../scss/contactForm.scss"
import "../scss/auditMenu.scss"

class Navbar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      isOpen: false,
      auditActive: false,
      navBarActiveClass: "",
      auditMenuActiveClass: "",
    }
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({ navBarActiveClass: "is-active" })
          : this.setState({ navBarActiveClass: "" })
      }
    )
  }

  render() {
    return (
      <nav
        className={this.props.slug === "/" ? "navbar is-transparent" : "navbar"}
        role="navigation"
        aria-label="main-navigation"
      >
        <AnnouncementBar />
        <div className="container-max-width">
          <div className="container">
            <div className="navbar-brand" style={{ minWidth: `100%` }}>
              <img
                src={logoBlack}
                alt="Next and Co"
                style={{ height: "17px" }}
              />
              <a
                className="phone-number"
                href="tel:1300191950"
                style={{
                  textDecoration: `none`,
                  fontWeight: `800`,
                }}
              >
                1300 191 950
              </a>
            </div>
          </div>
        </div>
      </nav>
    )
  }
}

export default Navbar
