import React from "react"
import { Link } from "gatsby"
import logo from "../images/logo-white.svg"
import "../scss/footer.scss"

const Footer = () => {
  return (
    <>
	<footer className="footer">
            <div className="container-max-width">
		<div className="container">
		    <div className="footer-brand">
			<Link to="/" className="navbar-item" title="Logo">
			    <img src={logo} alt="Next and Co" />
			</Link>
			<div className="divider"></div>
			<div className="slogan">
			    <span>
				<p>The Continuous Improvement Agency.<Link to="/privacy">privacy policy</Link></p>
			    </span>

			</div>
		    </div>
		    <div className="footer-menu">
			<div className="content">
			    <p>© 2020 Level 3 424 St Kilda Road, Melbourne, Victoria 3004</p>
			</div>
		    </div>
		</div>
            </div>
	</footer>
	<div className="ios-footer">
            <div className="footer-container"></div>
	</div>
    </>
  )
}

export default Footer
